/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser } from '@remix-run/react';
import { startTransition, StrictMode } from 'react';
import { hydrateRoot } from 'react-dom/client';
import { initReactI18next } from 'react-i18next';
import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import i18n from '~/localization/i18n';
import { getInitialNamespaces } from 'remix-i18next/client';
import enTranslations from 'zod-i18n-map/locales/en/zod.json';
import jaTranslations from 'zod-i18n-map/locales/ja/zod.json';
import { z } from 'zod';
import { zodI18nMap } from 'zod-i18n-map';
import { Providers } from '~/components/Providers';

if (ENV.MODE === 'production' && ENV.SENTRY_DSN) {
  void import('./utils/monitoring.client').then(({ init }) => init());
}

async function hydrate() {
  await i18next
    .use(initReactI18next)
    .use(LanguageDetector)
    .use(Backend)
    .init({
      ...i18n,
      ns: getInitialNamespaces(),
      backend: { loadPath: '/locales/{{lng}}/{{ns}}.json' },
      partialBundledLanguages: true,
      resources: {
        en: {
          zod: enTranslations,
        },
        ja: {
          zod: jaTranslations,
        },
      },
      detection: {
        // Only detect language server-side, use html lang attribute to communicate this to client
        order: ['htmlTag'],
        // Disable cache as there is no reason to cache the language on the client
        caches: [],
      },
    });

  z.setErrorMap(zodI18nMap);

  startTransition(() => {
    hydrateRoot(
      document,
      <Providers i18n={i18next}>
        <StrictMode>
          <RemixBrowser />
        </StrictMode>
      </Providers>,
    );
  });
}

if (window.requestIdleCallback) {
  window.requestIdleCallback(hydrate);
} else {
  // Safari does not support requestIdleCallback
  window.setTimeout(hydrate, 1);
}
