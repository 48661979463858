import type { i18n } from 'i18next';
import type { PropsWithChildren } from 'react';
import { I18nextProvider } from 'react-i18next';
import { TooltipProvider } from 'ui';
import { NonceProvider } from '~/utils/nonce-provider';

type ProvidersProps = PropsWithChildren<{
  i18n: i18n;
  nonce?: string;
}>;

export function Providers({ i18n, nonce, children }: ProvidersProps) {
  return (
    <NonceProvider value={nonce}>
      <I18nextProvider i18n={i18n}>
        <TooltipProvider delayDuration={10}>{children}</TooltipProvider>
      </I18nextProvider>
    </NonceProvider>
  );
}
